<template>
  <div class="div_title" >
    <span style="color: #ff7f50;">재고현황</span> 
    <span>
      <span class="flex items-center">
        <Checkbox inputId="empty"  style="vertical-align: middle;margin:5px;" v-model="empty" :binary="true" />
        <label for="empty" >0개 포함</label>
      </span>
      <select v-if="this.api.getCookies('page') === 'admin' && this.api.getCookies('id') === '1513898286'" style="width:90px;text-align:center;padding:5px;" v-model="selectedCompanyNum"  > 
        <option value="">전체</option>
        <option v-for="(company, index) in companyList" :value="company.num" v-bind:key="index">{{company.name}}</option>
      </select>
      <Button label="조회" v-on:click="click({type:'possess'})" raised size="small" style="float:right;"></Button>
      <Button label="이력" v-on:click="this.$emit('click', {type:'history'})" raised size="small" style="margin-right:5px;margin-left:5px;"></Button>
      <Button label="수정" v-on:click="this.$emit('click', {type:'modify'})" raised size="small" v-if="this.page === 'admin' && this.api.getCookies('id') === '1513898286'" style="margin-right:5px;"></Button>
    </span>
  </div>
  <div v-if="possessList.length === 0" class="card main no-result" style="margin-top: 10px;margin-bottom: 40px;"> 
    조회 내역이 없습니다.
  </div>
  <div v-else class="card main container_no_login" style="margin-top: 10px;margin-bottom: 40px;">
    <table v-if="this.page === 'admin'" id="table-possess" style="width:100%;" ref="table_val">
      <tr v-for="(item, index) in possessList" :key="index">
        <td v-if="index%2 === 0" style="width:40%;">{{possessList[index].NAME}}</td>
        <td v-if="index%2 === 0" style="width:10%;font-style: italic;">
          <input type="text" :id="possessList[index].NAME" :value="possessList[index].STOCK" style="max-width:40px; text-align:center;">
        </td>
        <td v-if="index+1 <= possessList.length-1 && index%2 === 0" style="width:40%;">{{possessList[index+1].NAME}}</td>
        <td v-if="index+1 <= possessList.length-1 && index%2 === 0" style="width:10%;font-style: italic;">
          <input type="text" :id="possessList[index+1].NAME" :value="possessList[index+1].STOCK" style="max-width:40px; text-align:center;">
        </td>
      </tr>
    </table>
    <table v-else-if="this.page === 'contract'" id="table-possess" style="width:100%;" ref="table_val">
      <tr v-for="(item, index) in possessList" :key="index">
        <td v-if="index%2 === 0" style="width:40%;">{{possessList[index].NAME}}</td>
        <td v-if="index%2 === 0" style="width:10%;font-style: italic;">{{possessList[index].STOCK}}</td>
        <td v-if="index+1 <= possessList.length-1 && index%2 === 0" style="width:40%;">{{possessList[index+1].NAME}}</td>
        <td v-if="index+1 <= possessList.length-1 && index%2 === 0" style="width:10%;font-style: italic;">{{possessList[index+1].STOCK}}</td>
      </tr>
    </table>
  </div>
</template>
<script>
import { ref } from 'vue'

export default {
  emits: ["click", "setPossess"],
  props: {
    possessList: ref([]),
    num: String,
    page: String,
    isEmpty: Boolean
  },
  components: {
  },  
  unmounted() {
    this.emitter.off('setPossess', this.setPossess)
  },
  mounted() {
    this.emitter.on('setPossess', this.setPossess)
    this.init()
    this.selectedCompanyNum = this.num
    if(this.isEmpty !== undefined) this.empty = this.isEmpty
  }, 
  data() { 
    return { 
      selectedCompanyNum:'',
      companyList:[],
      empty:false
    }
  },
  methods: {
    async init() {
      this.api.axiosGql('SELECT_COMPANY', {type:'SELECT_COMPANY'})
      .then( async (res) => {
        res.data.forEach((company) => {
          this.companyList.push({name:company.NAME, num:company.NUM })
        })
      })
    },
    click(param) {
      const type = param.type
      if(type === 'possess') {
        if(this.selectedCompanyNum === '') this.selectedCompanyNum = undefined
        this.$emit('click', {type:'possess', num:this.selectedCompanyNum, isEmpty:this.empty})
      }
    },
    setPossess(param) {
      const itemName = param.itemName
      let notice = param.notice
      
      if(itemName === 'Note Air3 C') {
        const itemList = notice.split('재고 ')[1]
        let tmp = itemList.split(',')
        for(let i=0; i<tmp.length; i++) {
          let splited = tmp[i].split(':')
          let name = splited[0]
          let amount = Number(splited[1].split('개')[0])
          let id = ''
          if(name.indexOf('노트 에어3 C') > -1) id = 'Note Air3 C'
          else if(name.indexOf('마그네틱케이스')  > -1) id = 'Note Air3 C Case'
          let modifiedInput = document.getElementById(id) 
          modifiedInput.value = amount
          modifiedInput.style.color = 'red'
        }
      } else if(itemName === 'Tab Ultra') {
        const itemList = notice.split('재고 ')[1]
        let tmp = itemList.split(',')
        for(let i=0; i<tmp.length; i++) {
          let splited = tmp[i].split(':')
          let name = splited[0]
          let amount = Number(splited[1].split('개')[0])
          let id = ''
          if(name.indexOf('울트라') > -1) id = 'Tab Ultra'
          else if(name.indexOf('접이식')  > -1) id = 'Tab Ultra Cover'
          else if(name.indexOf('키보드')  > -1) id = 'Tab Ultra Keyboard Cover'
          let modifiedInput = document.getElementById(id) 
          modifiedInput.value = amount
          modifiedInput.style.color = 'red'
        }
      } else if(itemName === 'Tab UltraC') {
        const itemList = notice.split('재고 ')[1]
        let tmp = itemList.split(',')
        for(let i=0; i<tmp.length; i++) {
          let splited = tmp[i].split(':')
          let name = splited[0]
          let amount = Number(splited[1].split('개')[0])
          let id = ''
          if(name.indexOf('울트라C') > -1) id = 'Tab UltraC'
          else if(name.indexOf('접이식')  > -1) id = 'Tab Ultra Cover'
          else if(name.indexOf('키보드')  > -1) id = 'Tab Ultra Keyboard Cover'
          let modifiedInput = document.getElementById(id) 
          modifiedInput.value = amount
          modifiedInput.style.color = 'red'
        }
      } else if(itemName === 'TabX') {
        const itemList = notice.split('재고 ')[1]
        let tmp = itemList.split(',')
        for(let i=0; i<tmp.length; i++) {
          let splited = tmp[i].split(':')
          let name = splited[0]
          let amount = Number(splited[1].split('개')[0])
          let id = ''
          if(name.indexOf('TabX') > -1) id = 'TabX'
          else if(name.indexOf('케이스')  > -1) id = 'TabX Cover'
          let modifiedInput = document.getElementById(id) 
          modifiedInput.value = amount
          modifiedInput.style.color = 'red'
        }
      } else if(itemName.indexOf('Air2') > -1) {
        const itemList = notice.split('재고 ')[1]
        let tmp = itemList.split(',')
        for(let i=0; i<tmp.length; i++) {
          let splited = tmp[i].split(':')
          let name = splited[0]
          let amount = Number(splited[1].split('개')[0])
          let id = ''
          if(name.indexOf('에어2플러스') > -1) id = 'Note Air2 plus'
          else if(name.indexOf('10.3') > -1) id = '10.3 Case'
          else if(name.indexOf('마그네틱') > -1) id = 'Note Air Magnetic'
          else if(name.indexOf('스탠딩케이스') > -1) id = 'Note Air Case'
          else if(name.indexOf('air2') > -1) id = 'Note Air2'
          let modifiedInput = document.getElementById(id) 
          modifiedInput.value = amount
          modifiedInput.style.color = 'red'
        }
      } else {
        const stock = Number(notice.split(':')[1])
        let modifiedInput = document.getElementById(itemName)
        modifiedInput.value = stock
        modifiedInput.style.color = 'red'
      }
      this.$notify('재고가 수정되었습니다.')
    }
  }
}
</script>

<style scoped>
#table-order tr:nth-child(even){
  background-color: aliceblue;
}
#table-possess td:nth-child(odd){
  background-color: aliceblue;
  height:30px;
}
</style>
<template>
  <div class="container contract-item" >
    <table style="width:100%;">
      <tr>
        <td>상품명</td>
        <td>
          <select class="normalInput" ref="contract_select_box" @change="changed"> 
            <option v-for="(item, index) in itemList" :value="item.NAME.trim()" v-bind:key="index" >{{item.NAME.trim()}}</option>
          </select>
        </td>
        <td>주문수량</td>
        <td><input type="number" :value="amount" class="normalInput" /></td>
      </tr>
      <tr>
        <td>이름</td>
        <td><input type="text" :value="orderName" class="normalInput" /></td>
        <td>폰번호</td>
        <td><input type="number" :value="phone" class="normalInput" /></td>
      </tr>
      <tr>
        <td>통관부호</td>
        <td><input type="text" :value="clearance" class="normalInput" /></td>
        <td>우편번호</td>
        <td><input type="text" :value="zipCode" class="normalInput" /></td>
      </tr>
      <tr>
        <td>도로명<br>주소</td>
        <td><input type="text" :value="addrRoad " class="normalInput" /></td>
        <td>상세주소</td>
        <td><input type="text" :value="addrDetail" class="normalInput" /></td>
      </tr>
      <tr>
        <td>브랜드</td>
        <td><input type="text" :value="this.itemList[this.selectedIdx].BRAND" class="normalInput" ref="contract_brand" disabled /></td>
        <td>국가</td>
        <td><input type="text" :value="this.itemList[this.selectedIdx].COUNTRY" class="normalInput" ref="contract_country" disabled /></td>
      </tr>
      <tr>
        <td>해외가격</td>
        <td><input type="text" :value="this.itemList[this.selectedIdx].ORIGIN_PRICE" class="normalInput" ref="contract_fprice" disabled /></td>
        <td>판매가격</td>
        <td><input type="text" :value="this.itemList[this.selectedIdx].SELL_PRICE" class="normalInput" ref="contract_sprice" disabled /></td>
      </tr>
      <tr>
        <td style="border-left:0px;vertical-align: middle;">상품링크</td>
        <td colspan="3" style="border-right:0px; height:30px;vertical-align: middle;"><input type="text" :value="this.itemList[this.selectedIdx].URL" class="normalInput" ref="contract_url" disabled /></td>
      </tr>
      <tr>
        <td style="border-left:0px;vertical-align: middle;">사진링크</td>
        <td colspan="3" style="border-right:0px; height:30px;vertical-align: middle;"><input type="text" :value="this.itemList[this.selectedIdx].IMG_URL" class="normalInput" disabled /></td>
      </tr>
      <tr>
        <td style="border-left:0px;vertical-align: middle;">기타</td>
        <td colspan="3" style="border-right:0px; height:30px;vertical-align: middle;"><input type="text" :value="content" class="normalInput" /></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    itemName: String,
    amount: Number,
    orderName: String,
    phone: String,
    clearance: String,
    zipCode: String,
    addrRoad: String,
    addrDetail: String,
    content: String,
    itemList: ref([])
  },
  mounted() {
    this.itemList.forEach((item, index) => {
      if (item.NAME.trim() === this.itemName) {
        this.$refs.contract_select_box.selectedIndex = index
        this.selectedIdx = index
        return
      }
    })
  },
  data() { 
    return { 
      selectedIdx: 0
    } 
  },
  methods: {
    changed() {
      this.itemList.forEach((item) => {
        const selectedItemName = this.$refs.contract_select_box.value
        if (item.NAME.trim() === selectedItemName) {
          this.$refs.contract_brand.value = item.BRAND
          this.$refs.contract_country.value = item.COUNTRY
          this.$refs.contract_fprice.value = item.ORIGIN_PRICE
          this.$refs.contract_sprice.value = item.SELL_PRICE
          this.$refs.contract_url.value = item.URL
        }
      })
    }
  }
}
</script>

<style scoped>
.contract-item > table > tr > td:nth-child(odd) {
  font-weight: bold;
  min-width:100px;
  text-align:center;
  height:40px;
}
</style>

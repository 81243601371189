<template>
  <div class="container contract-item">
    <table style="width:100%;">
      <tr>
        <td>상품명</td>
        <td>
          <select class="normalInput" ref="dcontract_select_box" @change="click({type:'change'})"> 
            <option v-for="(item, index) in itemList" :value="item.NAME.trim()" v-bind:key="index" >{{item.NAME.trim()}}</option>
          </select>
        </td>
        <td>주문수량</td>
        <td><input type="number" class="normalInput" ref="dcontract_amount" /></td>
      </tr>
      <tr>
        <td>이름</td>
        <td><input type="text" class="normalInput" ref="dcontract_orderName"/></td>
        <td>폰번호</td>
        <td><input type="text" class="normalInput" ref="dcontract_phone"/></td>
      </tr>
      <tr>
        <td>통관부호</td>
        <td><input type="text" class="normalInput" ref="dcontract_clearance" /></td>
        <td>우편번호</td>
        <td><input type="text" class="normalInput" ref="dcontract_zipcode"/></td>
      </tr>
      <tr>
        <td>도로명<br>주소</td>
        <td><input type="text" class="normalInput" ref="dcontract_addrRoad" /></td>
        <td>상세주소</td>
        <td><input type="text" class="normalInput" ref="dcontract_addrDetail"/></td>
      </tr>
      <tr>
        <td>브랜드</td>
        <td><input type="text" class="normalInput" ref="dcontract_brand" disabled/></td>
        <td>국가</td>
        <td><input type="text" class="normalInput" ref="dcontract_country" disabled/></td>
      </tr>
      <tr>
        <td>해외가격</td>
        <td><input type="text" class="normalInput" ref="dcontract_fprice" disabled/></td>
        <td>판매가격</td>
        <td><input type="text" class="normalInput" ref="dcontract_sprice" disabled/></td>
      </tr>
      <tr>
        <td style="border-left:0px;vertical-align: middle;">상품링크</td>
        <td colspan="3" style="border-right:0px; height:30px;vertical-align: middle;">
          <input type="text" class="normalInput" ref="dcontract_url" disabled />
        </td>
      </tr>
      <tr>
        <td style="border-left:0px;vertical-align: middle;">사진링크</td>
        <td colspan="3" style="border-right:0px; height:30px;vertical-align: middle;">
          <input type="text" class="normalInput" ref="dcontract_imgUrl" disabled />
        </td>
      </tr>
      <tr>
        <td style="border-left:0px;vertical-align: middle;">기타</td>
        <td colspan="3" style="border-right:0px; height:30px;vertical-align: middle;">
          <input type="text" class="normalInput" ref="dcontract_content"/>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          <Button style="margin-top:10px;float:none" @click="click({type:'add'})" label="추가" raised size="small" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  emits: ["setOrder"],
  mounted() {
    this.emitter.on('dorder', this.reload)
    this.click({type:'change'})
  },
  unmounted() {
    this.emitter.off('dorder', this.reload)
  },
  components: {
  },  
  props: {
    itemList: ref([]),
  },
  data() { 
    return { 
      orderList:Array()
    } 
  },
  methods: {
    reload() {
      this.orderList = Array()
    },
    click(param) {
      const type = param.type
      if(type === 'change') {
        this.itemList.forEach((item) => {
          const selectedItemName = this.$refs.dcontract_select_box.value
          if (item.NAME.trim() === selectedItemName) {
            this.$refs.dcontract_brand.value = item.BRAND
            this.$refs.dcontract_country.value = item.COUNTRY
            this.$refs.dcontract_fprice.value = item.ORIGIN_PRICE
            this.$refs.dcontract_sprice.value = item.SELL_PRICE
            this.$refs.dcontract_url.value = item.URL
          }
        })
      } else if(type === 'add') {
        const orderName = this.$refs.dcontract_orderName.value
        const amount = this.$refs.dcontract_amount.value
        const phone = this.$refs.dcontract_phone.value
        const clearance = this.$refs.dcontract_clearance.value
        const zipcode = this.$refs.dcontract_zipcode.value
        const addrRoad = this.$refs.dcontract_addrRoad.value
        
        if(orderName === '') {
          alert('주문자명을 입력해주세요.')
          this.$refs.dcontract_orderName.focus()
        } else if(phone === '') {
          alert('전화번호를 입력해주세요.')
          this.$refs.dcontract_phone.focus()
        } else if(clearance === '') {
          alert('통관부호를 입력해주세요.')
          this.$refs.dcontract_clearance.focus()
        } else if(amount === '') {
          alert('수량을 입력해주세요.')
          this.$refs.dcontract_amount.focus()
        } else if(zipcode === '') {
          alert('우편번호를 입력해주세요.')
          this.$refs.dcontract_zipcode.focus()
        } else if(addrRoad === '') {
          alert('도로명주소를 입력해주세요.')
          this.$refs.dcontract_addrRoad.focus()
        } else {
          let order = Array()
          const addrDetail = this.$refs.dcontract_addrDetail.value
          // const brand = this.$refs.dcontract_brand.value
          // const country = this.$refs.dcontract_country.value
          // const originPrice = this.$refs.dcontract_fprice.value
          // const sellPrice = this.$refs.dcontract_sprice.value
          // const url = this.$refs.dcontract_url.value
          // const imgUrl = this.$refs.dcontract_imgUrl.value
          const content = this.$refs.dcontract_content.value
          const itemName = this.$refs.dcontract_select_box.value
          order.push(itemName)
          order.push(amount)
          order.push(orderName)
          order.push(phone)
          order.push(clearance)
          order.push(zipcode)
          order.push(addrRoad)
          order.push(addrDetail)
          order.push(content)
          // order.push(imgUrl)
          this.orderList.push(order)
          this.$emit('setOrder', this.orderList)
          
          this.$refs.dcontract_orderName.value = ''
          this.$refs.dcontract_amount.value = ''
          this.$refs.dcontract_phone.value = ''
          this.$refs.dcontract_clearance.value = ''
          this.$refs.dcontract_zipcode.value = ''
          this.$refs.dcontract_addrRoad.value = ''
          this.$refs.dcontract_addrDetail.value = ''
          this.$refs.dcontract_content.value = ''
          return
        }
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
<style scoped>
.contract-item > table > tr > td:nth-child(odd) {
  font-weight: bold;
  min-width:100px;
  text-align:center;
  height:40px;
}
</style>

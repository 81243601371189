<template>
<div class="div_title" >
  <span style="color: #ff7f50;">주문정보</span>
</div>
<div class="card" style="background-color: #f9f9f9;">
  <table class="dynamic-table" style="margin-bottom:0px;">
    <tr style="height:100px;">
      <td>
        <table class="order_sub_table" style="table-layout:fixed;">
          <tbody style="text-align: left;">
            <tr style="height:30px;">
              <td class="container_sub_style">
                <div class="div_center order-div">
                  <div>IDX</div>
                  <div>
                    {{idx}}
                  </div>
                </div>
              </td>
              <td class="container_sub_style">
                <div class="div_center order-div">
                  <div>송장번호</div>
                  <div>
                    <a :href="this.api.getDeliveryUrl(invoiceNumber, deliveryKrCompany)" target="_blank">{{invoiceNumber}}</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr style="height:30px;">
              <td class="container_sub_style">
                <div class="div_center order-div">
                  <div>배송비</div>
                  <div>
                    {{Number(deliveryPrice2).toLocaleString()}}원
                  </div>
                </div>
              </td>
              <td class="container_sub_style">
                <div class="div_center order-div">
                  <div>진행상태</div>
                  <div>
                    {{status}}
                  </div>
                </div>
              </td>
            </tr>
            <tr style="height:30px;">
              <td class="container_sub_style">
                <div class="div_center order-div">
                  <div>국가</div>
                  <div>
                    {{country}}
                  </div>
                </div>
              </td>
              <td class="container_sub_style">
                <div class="div_center order-div">
                  <div>수량</div>
                  <div>
                    {{amount}} EA
                  </div>
                </div>
              </td>
            </tr>
            <tr style="height:30px;">
              <td class="container_sub_style" colspan="2">
                <div class="div_center order-div">
                  <div>상품명</div>
                  <div>
                    {{name}}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </table>
</div>
<div class="div_title" >
  <span style="color: #ff7f50;">수취인정보</span>
</div>
<div class="card" style="background-color: #f9f9f9;">
  <table class="dynamic-table" style="margin-bottom:0px;">
    <tr style="height:100px;">
      <td style="width:100%;">
        <table class="order_sub_table" style="table-layout:fixed;">
          <tbody style="text-align: left;">
            <tr style="height:30px;">
              <td class="container_sub_style">
                <div class="div_center order-div">
                  <div>이름</div>
                  <div>
                    {{orderName}}
                  </div>
                </div>
              </td>
              <td class="container_sub_style">
                <div class="div_center order-div">
                  <div>폰번호</div>
                  <div>
                    {{phone}}
                  </div>
                </div>
              </td>
            </tr>
            <tr style="height:30px;">
              <td class="container_sub_style" colspan="2">
                <div class="div_center order-div">
                  <div>통관부호</div>
                  <div>
                    {{ clearance }}
                  </div>
                </div>
              </td>
            </tr>
            <tr style="height:30px;">
              <td class="container_sub_style" colspan="2">
                <div class="div_center order-div">
                  <div>주소</div>
                  <div>
                    {{ addrZip }} &nbsp; {{ addrRoad }} &nbsp; {{ addrDetail }}
                  </div>
                </div>
              </td>
            </tr>
            <tr style="height:30px;">
              <td class="container_sub_style" colspan="2">
                <div class="div_center order-div">
                  <div>기타</div>
                  <div>
                    {{ notice }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </table>
</div>
</template>
<script>
export default {
  mounted() {
    const page = this.api.getCookies('page')
    if(page === 'contract' || page === 'admin') this.refresh({idx:this.idx, uid:this.uid})
    else this.emitter.on('orderRefresh' + this.idx, this.refresh)
  },
  unmounted() {
    this.emitter.off('orderRefresh' + this.idx, this.refresh)
  },
  props: {
    name: String,
    deliveryKrCompany: String,
    idx: Number,
    seq: Number,
    amount: Number,
    country: String,
    invoiceNumber: String,
    deliveryPrice2: Number,
    status: String,
    notice: String,
    uid: String
  },
  data() {
    return { 
      orderName:'',
      addrZip:'',
      addrRoad:'',
      addrDetail:'',
      delivertKrCompany:'',
      clearance:'',
      phone:'',
    }
  },
  methods: {
    async refresh(param) {
      if(this.idx === param.idx) {
        await this.api.axiosGql('SELECT_ORDER_INFO_BY_UID', {type:'SELECT_ORDER_INFO_BY_UID', ID: "", UID:param.uid})
        .then( async (res) => {
          let result = res.data[0]
          this.orderName = result.ORDER_NAME
          this.clearance = result.CLEARANCE
          this.addrRoad = result.ADDR_ROAD
          this.addrDetail = result.ADDR_DETAIL
          this.delivertKrCompany = result.DELIVERY_KR_COMPANY
          this.phone = result.PHONE
        }).catch( (err) => {
          console.log(err)
        })
      }
    },
  }
}
</script>